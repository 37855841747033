// takes advantage of Bootstrap 4 variables, however it's not required for essential layout
@import "variables";

@import "layout/base";
@import "layout/header";
@import "layout/sidebar";
@import "layout/page-container";
@import "layout/footer";
//@import "layout/quickview";
//@import "layout/overlay";
//@import "layout/boxed";
@import "layout/sidebar-removed";
@import "layout/sidebar-addons";
@import "layout/sidebar-collapsed";
@import "layout/sidebar-behind";
@import "layout/sidebar-width";
@import "layout/header-theme";
@import "layout/sidebar-theme";
@import "layout/page";
@import "layout/page-container-theme";
//@import "layout/overlay-theme";
//@import "layout/quickview-app";
//@import "layout/quickview-customizer";
//@import "layout/quickview-customizer-antd";
//@import "layout/md-sidenav-right";

.app-notification-panel{
    display: flex;
    align-items: center;
    z-index: 99;
    position: fixed;
    top: 60px;
    bottom: 0;
    right: 0;
    width: 2%;
    overflow: hidden;
    background-color: transparent;
}
